<template>
  <div
    class="empty:hidden"
    :class="[
      {
        [style.rteParserPrimaryLight]: lightText,
        [style.rteParser]: defaultStyle,
      },
    ]"
    data-allow-mismatch="children"
  >
    <RichTextVueRenderer
      v-if="parsedRichtext"
      :document="parsedRichtext"
      :mark-renderers="customMarkRenderers"
      :node-renderers="customNodeRenderers"
    />
  </div>
</template>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./contentful-rich-text-vue-renderer.d.ts" />

import { computed, h, useCssModule } from 'vue'

import { findTitlesInRichtext } from '@backmarket/front-office/scopes/article/Article/components/TableOfContents/TableOfContent.utils'
import type { RichText } from '@backmarket/http-api/src/api-specs-content/models/rich-text'
import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import {
  BLOCKS,
  type Heading1,
  type Heading2,
  type Heading3,
  type Hyperlink,
  INLINES,
  type Node,
  type OrderedList,
  type Paragraph,
  type UnorderedList,
} from '@contentful/rich-text-types'
// eslint-disable-next-line import/order
import { RevLink } from '@ds/components/Link'

import RichTextVueRenderer, {
  type AllMarks,
  type MarkRendererMap,
  type NextRendererFn,
  type NodeRendererMap,
} from 'contentful-rich-text-vue-renderer'

import { textToTarget } from '@backmarket/nuxt-layer-cms/utils/textToTarget'

import MarkColorStyler from './MarkStyles/MarkColorStyler.vue'
import { COLOR_MARK_REGEX } from './MarkStyles/MarkStyles.constants'

const props = withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
  ignoreLinks: false,
  defaultStyle: true,
})
const style = useCssModule()

const parsedRichtext = computed<RichText | null>(() => {
  if (isEmpty(props.richText)) return null

  return props.richText as RichText
})

const customMarkRenderers: MarkRendererMap<{
  'text-color-mark': Node
}> = {
  'text-color-mark': (text: string[]) => {
    return h(MarkColorStyler, { text: text.join('') })
  },
}

const customNodeRenderers: NodeRendererMap<{
  [INLINES.HYPERLINK]: Hyperlink
  [BLOCKS.HEADING_1]: Heading1
  [BLOCKS.HEADING_2]: Heading2
  [BLOCKS.HEADING_3]: Heading3
  [BLOCKS.UL_LIST]: UnorderedList
  [BLOCKS.OL_LIST]: OrderedList
  [BLOCKS.PARAGRAPH]: Paragraph
}> = {
  [INLINES.HYPERLINK]: (node: Hyperlink, key: string, next: NextRendererFn) => {
    if (props.ignoreLinks) return next(node.content, key, next)

    const { uri } = node.data

    return h(RevLink, { to: uri }, () => next(node.content, key, next))
  },
  [BLOCKS.HEADING_1]: (node: Heading1, key: string, next: NextRendererFn) => {
    return h('h1', { key, class: tw`heading-1` }, next(node.content, key, next))
  },
  [BLOCKS.HEADING_2]: (node: Heading2, key: string, next: NextRendererFn) => {
    const title = findTitlesInRichtext(node.content, node.nodeType)
    const id = title.length ? textToTarget(title[0].title, node.nodeType) : ''

    return h(
      'h2',
      { key, class: tw`heading-2`, id },
      next(node.content, key, next),
    )
  },
  [BLOCKS.HEADING_3]: (node: Heading3, key: string, next: NextRendererFn) => {
    const title = findTitlesInRichtext(node.content, node.nodeType)
    const id = title.length ? textToTarget(title[0].title, node.nodeType) : ''

    return h(
      'h3',
      { key, class: tw`heading-3`, id },
      next(node.content, key, next),
    )
  },
  [BLOCKS.UL_LIST]: (
    node: UnorderedList,
    key: string,
    next: NextRendererFn,
  ) => {
    return h(
      'ul',
      { key, class: tw`list-disc px-20` },
      next(node.content, key, next),
    )
  },
  [BLOCKS.OL_LIST]: (node: OrderedList, key: string, next: NextRendererFn) => {
    return h(
      'ol',
      { key, class: tw`list-decimal px-20` },
      next(node.content, key, next),
    )
  },
  [BLOCKS.PARAGRAPH]: (node: Paragraph, key: string, next: NextRendererFn) => {
    for (let i = 0; i < node.content.length; i += 1) {
      const content = node.content[i]
      if (content.nodeType === 'text') {
        const COLOR_MARK: AllMarks = 'text-color-mark'
        const match = content.value.match(COLOR_MARK_REGEX)

        if (match && !content.marks.find((m) => m.type === COLOR_MARK)) {
          content.marks.push({ type: COLOR_MARK })
        }
      }
    }

    return h('p', { key }, next(node.content, key, next))
  },
}
</script>

<style module>
.rteParser {
  @apply body-1 text-static-default-mid flex flex-col mx-auto w-full gap-24 items-center;
}

@media (min-width: theme('screens.md')) {
  .rteParser {
    max-width: 112rem;
  }
}

/* don't display the last <p> tags with empty content */
.rteParser p:nth-last-of-type(1):empty {
  display: none;
}

.rteParser > :not(table) {
  width: 100%;
  max-width: 736px;
}

@media (min-width: theme('screens.lg')) {
  .rteParser > :not(table) {
    width: 736px;
    max-width: 100%;
  }
}

.rteParser > *:last-child {
  margin-bottom: 0;
}

.rteParser blockquote {
  @apply text-static-default-mid heading-1-italic text-center my-32;
}

.rteParser table {
  @apply bg-surface-default-low shadow-short pt-24 pb-32 py-16 px-24 mb-56 block overflow-x-auto;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100vw;
  border-radius: 0;
}

@media (min-width: theme('screens.md')) {
  .rteParser table {
    @apply rounded-lg mb-72 p-32;
    max-width: 1120px;
    width: auto;
  }
}

.rteParser table tr {
  @apply border-b-1 border-b-static-default-low;
  min-width: 160px;
  max-width: 100%;
}

.rteParser table tr:last-child {
  border-bottom: 0;
}

.rteParser table tbody {
  border-color: inherit;
  width: 100%;
}

.rteParser table p {
  margin-bottom: 0;
}

.rteParser table th,
.rteParser table td {
  @apply py-12 px-8 text-left;
  min-width: 160px;
  max-width: 100%;
}

.rteParser table tr:first-child th {
  padding-top: 0;
}

.rteParser table tr:last-child td {
  padding-bottom: 0;
}

@media (min-width: theme('screens.md')) {
  .rteParser table th,
  .rteParser table td {
    @apply p-16;
  }
}

.rteParserPrimaryLight {
  @apply text-static-default-low;
}
</style>
