<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
  <span v-html="finalRender" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'

import type { TextColor } from '../text-color'

import { COLOR_MARK_REGEX } from './MarkStyles.constants'

const props = defineProps<{ text: string }>()

const colorClasses: Record<TextColor, string> = {
  Black: tw`text-static-default-hi`,
  Bush: tw`mood-emerald text-static-default-mid`,
  'Coffee Bean': tw`mood-blush text-static-default-mid`,
  'Cornflower Blue': tw`mood-tangaroa text-static-default-mid`,
  'Deep Blush': tw`mood-coffee text-static-default-mid`,
  Emerald: tw`mood-bush text-static-default-mid`,
  'Medium Purple': tw`mood-violet text-static-default-mid`,
  Mindaro: tw`mood-coffee text-static-default-hi`,
  Tangaroa: tw`mood-cornflower text-static-default-hi`,
  Violet: tw`mood-purple text-static-default-mid`,
  White: tw`text-static-light-hi`,
}

const finalRender = computed(() => {
  const matches = [...props.text.matchAll(COLOR_MARK_REGEX)]
  if (!matches.length) return props.text

  let finalText = props.text
  for (let i = 0; i < matches.length; i += 1) {
    const color = matches[i][1] as TextColor
    const text = matches[i][2]

    if (!colorClasses[color]) continue

    finalText = finalText.replace(
      `[${color}](${text})`,
      `<span class="${colorClasses[color]}">${text}</span>`,
    )
  }

  return finalText
})
</script>
